import React from "react";
import PropTypes from "prop-types";

class Form extends React.Component {
    
    constructor(props) {
        super(props);
        this.props = props;
        this.handleSubmit = this.handleSubmit.bind(this);
      }

    handleSubmit(e) {
        console.log('The value is: ' + this.input.value);
        console.log('The value is: ' + this.input2.value);
        this.props.clickHandler(this.input.value, this.input2.value);
        e.preventDefault();
      }

    render() {
        return (

            <form onSubmit={this.handleSubmit}>
                <div>
                    <label>
                    CustomerRef: 
                    <input class="form-control" type="text" ref={(input) => this.input = input} />
                    </label>
                </div>
                <div>
                    <label>
                    Status: 
                    <input class="form-control" type="text" ref={(input2) => this.input2 = input2} />
                    </label>
                </div>
                <input class="btn btn-default" type="submit" value="Submit" />
            </form>

        );
    }

}

Form.propTypes = {
    customerRef: PropTypes.string,
    clickHandler: PropTypes.func,
};

export default Form;