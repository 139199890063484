import * as jwtDecode from 'jwt-decode';
import React, { Component } from 'react';
import * as additionalMock from "./additional";
import * as addressesMock from "./addresses";
import * as customersMock from "./customer";
import './App.css';
import Form from "./Form";

class App extends Component {

  constructor(){
    super();
    this.state = {username:'default-username', password:'default-password'};
    this.handleNvEnter = this.handleNvEnter.bind(this);
    this.state.detail = this.getDelivery.bind(this);
    this.state.jwt = this.getJwt.bind(this);
    this.state.deliveryConfirmation = this.confirmOrder.bind(this);
    this.handleChangeConfiguration = this.handleChangeConfiguration.bind(this);
    this.handleChangeConf = this.handleChangeConf.bind(this);

    // const clientRef12 = [
    //   ["000007",	"NEW"],
    //   ["000018",	"NEW"],
    //   ["000043",	"NEW"],
    //   ["000056",	"NEW"],
    //   ["000163",	"NEW"],
    //   ["000167",	"NEW"],
    //   ["000190",	"NEW"],
    //   ["000220",	"NEW"],
    //   ["000262",	"NEW"],
    //   ["000279",	"NEW"],
    //   ["000360",	"NEW"],
    //   ["000436",	"NEW"],
    //   ["000540",	"NEW"],
    //   ["000584",	"NEW"],
    //   ["000602",	"NEW"],
    //   ["000681",	"NEW"],
    //   ["000707",	"NEW"],
    //   ["000713",	"NEW"],
    //   ["000719",	"NEW"],
    //   ["000847",	"NEW"],
    // ];

    this.state = {
      configuration: {
          userToken: "",
       },
          
      shown1: false,

      clientRef: "123",
      detail: null,
      jwt: null,
      deliveryConfirmation: null
    };

    this.getJwt('test', null, null);

}

customJWT = (e) => {
    console.log('The value is: ' + this.input.value);
    const jwt = this.input.value;
    this.getJwt(null, null, jwt);

    e.preventDefault();
}
  
getJwt = (ref, status, jwt) => {

    const random = Math.abs(Math.floor(Math.random() * additionalMock.additional.length-1-5), 0);
    const random2 = Math.abs(Math.floor(Math.random() * addressesMock.addresses.length-1-5), 0);

    console.log(random)
    console.log(random2)

    var locationListMock = [];

    for (let i = 0; i < 5; i++) {
      locationListMock.push({
        "additional": {
          "companyName": additionalMock.additional[random%i],
          "contactName": additionalMock.name[random%i],
          "contactPhoneNO": "",
          "domofonCode": "",
          "flatNO": "",
          "floor": 0,
          "lift": 0,
          "staircaseNO": ""
        },
        "address": {
          "country": addressesMock.addresses[random2+i][4],
          "district": addressesMock.addresses[random2+i][2],
          "houseNO": addressesMock.addresses[random2+i][1],
          "street": addressesMock.addresses[random2+i][5],
          "town": addressesMock.addresses[random2+i][2],
          "voivoidship": addressesMock.addresses[random2+i][2],
          "zip": addressesMock.addresses[random2+i][3],
        },
        "coordinates": {
          "lat": addressesMock.addresses[random2+i][6],
          "lng": addressesMock.addresses[random2+i][7],
        },
        "currentReservation": "eyJhbGciOiJIUzI1NiJ9.eyJyZXNlcnZhdGlvbklkIjoiZGVsaXZlcnk6NzgzNjk4OjYxNjMwYWM3LWJjMTctNGQyMi1hMTE1LWIzNDM1MjFiNzBiMSIsImN1c3RvbWVyIjp7ImN1c3RvbWVySWQiOiIyNDFjMTZhYi0xZDdjLTRhODMtYWE5OC1kNTFiYzRkZWIzMTYiLCJjdXN0b21lclJlZiI6Ijc4MzY5OCIsImZpcnN0TmFtZSI6IkphcmVrIiwibGFzdE5hbWUiOiJLcnVwYSIsInBob25lTnVtYmVyIjoiNTEyMzIwNDk5Iiwic3RhdHVzIjoiTkVXIn0sInNsb3QiOnsiZGF0ZSI6IjIwMTktMDMtMTgiLCJzdGFydFRpbWUiOiIxOTowMDowMCIsImVuZFRpbWUiOiIyMDowMDowMCIsImN1dE9mZiI6IjIwMTktMDMtMTdUMjI6MDA6MDAuMDAwKzAwMDAiLCJzbG90IjoiMTk6MDAtMjA6MDAifSwibG9jYXRpb24iOnsibG9jYXRpb25JZCI6ImxvY2F0aW9uOjAwYzVlZGJmLTkwYmUtNDU1YS1iMzIyLWU0MWRhNzJjMWQzNSIsImxvY2F0aW9uUmVmIjoiNzgzNjk4LzAwMSIsImxhYmVsIjoiSmFyZWsgS3J1cGEiLCJhZGRyZXNzIjp7ImNvdW50cnkiOiJQb2xhbmQiLCJ2b2l2b2lkc2hpcCI6Im1hem93aWVja2llIiwidG93biI6IldhcnN6YXdhIiwiemlwIjoiMDAtNDAxIiwiZGlzdHJpY3QiOm51bGwsInN0cmVldCI6IkdyYcW8eW55IiwiaG91c2VOTyI6IjEyIn0sImFkZGl0aW9uYWwiOnsiY29tcGFueU5hbWUiOiIiLCJmbGF0Tk8iOiIzMiIsImxpZnQiOm51bGwsInN0YWlyY2FzZU5PIjoiIiwiZmxvb3IiOm51bGwsImNvbnRhY3ROYW1lIjoiSmFyZWsgS3J1cGEiLCJjb250YWN0UGhvbmVOTyI6IjUxMjMyMDQ5OSIsImRvbW9mb25Db2RlIjoiIiwiaW5zdHJ1Y3Rpb25zIjpudWxsfSwiY29vcmRpbmF0ZXMiOnsibGF0Ijo1Mi4yMDM5NTc5LCJsbmciOjIxLjAyMTE4Njl9LCJyZW1hcmtzIjpudWxsLCJyYXdBZGRyZXNzIjoiR3Jhxbx5bnkgMTIsIFdhcnN6YXdhIDAwLTQwMSwgUEwifSwiZXhwaXJhdGlvbiI6IjIwMTktMDMtMTRUMTM6NDA6MjIuNDM2KzAwMDAiLCJwYXltZW50VHlwZSI6IkNBUkQifQ.Q7TjJSkJwSrltn47gUuqLztHyFfLBo9ByWIlHIcnBds",
        "label": additionalMock.name[i],
        "locationId": addressesMock.addresses[random2+i][0],
        "locationRef": addressesMock.addresses[random2+i][0],
      })
    }

    if(ref != null){

      console.log('Pobieranie JWT ' + ref, status);

      const r3 = Math.random().toString(36).substring(7);

      ref = r3;

      status = "NEW";

      let formData = new FormData();
      formData.append('clientRef', r3);
      formData.append('status', "NEW");
    
        fetch("https://uat.open-routing.com/api/testdata/v1/jwt", {
            method: "POST",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                'Accept': 'text/plain;charset=UTF-8',
            },
            body: new URLSearchParams(`clientRef=${ref}&status=${status}`) //formData
        })
        .then(res => res.text())
        .then(res => {
            console.log(`JWT TOKEN: ${res}`);

            // this.state = {
            //   configuration: {
            //       "userToken": res,
            //   },
            //   shown1: false,
            //   shown2: false,
            //   jwt: JSON.stringify(res)
            // };

            var decoded = jwtDecode(res);

            console.log(decoded);

            this.setState({
              configuration: {
              userToken: res,
              locationList: locationListMock,
              selectedLocation: locationListMock[0],
              basketAmount: 100,
              mode: "NEW",
              customer: {
                "customerId": customersMock.customers[random][0],
                "customerRef": r3,
                "firstName":customersMock.customers[random][2],
                "lastName":customersMock.customers[random][3],
                "phoneNumber":customersMock.customers[random][4],
                "status": decoded.status
              },
              shown1: false,
        
              lang: 'pl-PL',
              currentReservation: "eyJhbGciOiJIUzI1NiJ9.eyJyZXNlcnZhdGlvbklkIjoiZGVsaXZlcnk6NzgzNjk4OjYxNjMwYWM3LWJjMTctNGQyMi1hMTE1LWIzNDM1MjFiNzBiMSIsImN1c3RvbWVyIjp7ImN1c3RvbWVySWQiOiIyNDFjMTZhYi0xZDdjLTRhODMtYWE5OC1kNTFiYzRkZWIzMTYiLCJjdXN0b21lclJlZiI6Ijc4MzY5OCIsImZpcnN0TmFtZSI6IkphcmVrIiwibGFzdE5hbWUiOiJLcnVwYSIsInBob25lTnVtYmVyIjoiNTEyMzIwNDk5Iiwic3RhdHVzIjoiTkVXIn0sInNsb3QiOnsiZGF0ZSI6IjIwMTktMDMtMTgiLCJzdGFydFRpbWUiOiIxOTowMDowMCIsImVuZFRpbWUiOiIyMDowMDowMCIsImN1dE9mZiI6IjIwMTktMDMtMTdUMjI6MDA6MDAuMDAwKzAwMDAiLCJzbG90IjoiMTk6MDAtMjA6MDAifSwibG9jYXRpb24iOnsibG9jYXRpb25JZCI6ImxvY2F0aW9uOjAwYzVlZGJmLTkwYmUtNDU1YS1iMzIyLWU0MWRhNzJjMWQzNSIsImxvY2F0aW9uUmVmIjoiNzgzNjk4LzAwMSIsImxhYmVsIjoiSmFyZWsgS3J1cGEiLCJhZGRyZXNzIjp7ImNvdW50cnkiOiJQb2xhbmQiLCJ2b2l2b2lkc2hpcCI6Im1hem93aWVja2llIiwidG93biI6IldhcnN6YXdhIiwiemlwIjoiMDAtNDAxIiwiZGlzdHJpY3QiOm51bGwsInN0cmVldCI6IkdyYcW8eW55IiwiaG91c2VOTyI6IjEyIn0sImFkZGl0aW9uYWwiOnsiY29tcGFueU5hbWUiOiIiLCJmbGF0Tk8iOiIzMiIsImxpZnQiOm51bGwsInN0YWlyY2FzZU5PIjoiIiwiZmxvb3IiOm51bGwsImNvbnRhY3ROYW1lIjoiSmFyZWsgS3J1cGEiLCJjb250YWN0UGhvbmVOTyI6IjUxMjMyMDQ5OSIsImRvbW9mb25Db2RlIjoiIiwiaW5zdHJ1Y3Rpb25zIjpudWxsfSwiY29vcmRpbmF0ZXMiOnsibGF0Ijo1Mi4yMDM5NTc5LCJsbmciOjIxLjAyMTE4Njl9LCJyZW1hcmtzIjpudWxsLCJyYXdBZGRyZXNzIjoiR3Jhxbx5bnkgMTIsIFdhcnN6YXdhIDAwLTQwMSwgUEwifSwiZXhwaXJhdGlvbiI6IjIwMTktMDMtMTRUMTM6NDA6MjIuNDM2KzAwMDAiLCJwYXltZW50VHlwZSI6IkNBUkQifQ.Q7TjJSkJwSrltn47gUuqLztHyFfLBo9ByWIlHIcnBds",
              reservation: null,
              //reservation: {"customer":{"customerId":"customer:0ababe87-717c-4cf5-9888-1647258f469d","firstName":"Ashely","lastName":"Almond","phoneNumber":"1","status":"NEW"},"location":{"additional":{"companyName":"","contactName":"","contactPhoneNO":"","domofonCode":"","flatNO":"","floor":0,"lift":0,"staircaseNO":""},"address":{"country":"Poland","district":"Warszawa","houseNO":"35","street":"Bajana 1","town":"Warszawa","voivoidship":"Warszawa","zip":"01-905"},"coordinates":{"lat":"52.2744999","lng":"20.9341799"},"label":"Dom","locationId":"125a7eae-e39f-460a-b26b-6deabb991452","locationRef":"125a7eae-e39f-460a-b26b-6deabb991452"},"slot":{"cutOff":"2019-03-11T14:00:00.000+0000","date":"2019-03-12","endTime":"10:30:00","slot":"09:30-10:30","slotId":null,"startTime":"07:00:00"}},
              jwt: JSON.stringify(res)
              } 
            });

        });
      }else{

        console.log('Custom jwt token');

        var decoded = jwtDecode(jwt);

            console.log(decoded)

            this.setState({
              configuration: {
              userToken: jwt,
              locationList: locationListMock,
              selectedLocation: locationListMock[0],
              basketAmount: 100,
              mode: "NEW",
              customer: {
                "customerId": customersMock.customers[random][0],
                "customerRef": decoded.sub,
                "firstName":customersMock.customers[random][2],
                "lastName":customersMock.customers[random][3],
                "phoneNumber":customersMock.customers[random][4],
                "status": decoded.status
              },
              lang: 'pl-PL',
              reservation: null,
              //eservation: {"customer":{"customerId":"customer:0ababe87-717c-4cf5-9888-1647258f469d","firstName":"Ashely","lastName":"Almond","phoneNumber":"1","status":"NEW"},"location":{"additional":{"companyName":"","contactName":"","contactPhoneNO":"","domofonCode":"","flatNO":"","floor":0,"lift":0,"staircaseNO":""},"address":{"country":"Poland","district":"Warszawa","houseNO":"35","street":"Bajana 1","town":"Warszawa","voivoidship":"Warszawa","zip":"01-905"},"coordinates":{"lat":"52.2744999","lng":"20.9341799"},"label":"Dom","locationId":"125a7eae-e39f-460a-b26b-6deabb991452","locationRef":"125a7eae-e39f-460a-b26b-6deabb991452"},"slot":{"cutOff":"2019-03-11T14:00:00.000+0000","date":"2019-03-12","endTime":"10:30:00","slot":"09:30-10:30","slotId":null,"startTime":"07:00:00"}},

              shown1: false,
            
              jwt: JSON.stringify(jwt)
              } 
      })
    }
    
  }

      

  componentDidMount() {
      this.nv.addEventListener("reservationChanged", this.handleNvEnter);
      this.nv.addEventListener("locationsCreated", this.handleNvEnter2);
  }



  toggle1() {
		this.setState({
      shown1: !this.state.shown1,
		});
  }


  handleNvEnter(event){
    var decoded = jwtDecode(event.detail);
    console.log('Event recieved in react [ReservationChanged]', event);
    this.setState({
      //shown1: false,
      detail: JSON.stringify(decoded),
    })

  }

  handleNvEnter2(event){

    console.log('Event recieved in react [locationsCreated]', event);


  }


  getDelivery = () => {

    const body = {
      clientRef: "",
      location: {}
    };
   
    fetch("https://uat.open-routing.com/api/testdata/v1/delivery", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(body) //formData
    })
    .then(res => res.text())
    .then(res => {

      const delivery = JSON.parse(res);
      const decoded = JSON.parse(this.state.detail);


      const paymentInfo = {
        id: "1231/123/123",
        requestedType: decoded.paymentType,
        amountDue: 200,
        currency: "PLN"
      };
      
      setTimeout(()=>{

        var confirmationData = {
          delivery: {
            customer: decoded.customer,
            date: decoded.slot.date,
            id: decoded.id,
            items: delivery['order']['items'],
            location: decoded.location,
            paymentInfo: paymentInfo,
            slot: decoded.slot.slotId,
            status: "ORDERED"
          },
          slot: decoded
        }
  
    
        console.log(confirmationData)

        this.setState({
          deliveryConfirmation: JSON.stringify(confirmationData)
        })


      });

    });

    console.log('getDelivery', this.state);

  }

  handleChangeConfiguration = (event) => {

      // alert('An essay was submitted: ' + this.state.value);
      event.preventDefault();
    
  }

  handleChangeConf(event) {
    console.log(event)
    this.setState({configuration: JSON.parse(event.target.value)});
  }


  confirmOrder = () => {

    console.log(this.state.deliveryConfirmation)

    fetch("https://sys-fr.open-routing.com/booking/partner/v1/delivery", {
      method: "POST",
      headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Basic ZnJpc2NvLWNvbW1lcmNlOm05Wm5QU3h0Z2ZqSDM2NGY='
      },
      body: this.state.deliveryConfirmation //formData
    })
    .then(res => res.text())
    .then(res => {
      var res2 = null;
      try {
        res2 = JSON.parse(res)
      } catch (e) {
        res2 = false;
      }

      
      if(!res2){
        alert('New reservation has been created!');
        console.log(`DONE!`, res);
      }else{
        alert('ERROR', res2.message);
      }
    });

  }



  handleChange = (event) => {
    this.setState({
      clientRef: event.target.value
    });
  }

  handleSubmit(event) {
    console.log('asdas')
    alert('Ustawiono numer klienta na: ' + this.state.clientRef);
    event.preventDefault();

    this.start();
  }


  handleClick = (ref, status) => {
    console.log('Ustawiono numer i status klienta: ' + ref, status);
    this.getJwt(ref, status);
  };

  render() {

    var shown1 = {
      display: this.state.shown1 ? "block" : "none",
      transition: 'display 2s linear 1s'
    };
    
    // var shown2 = {
    //   display: this.state.shown2 ? "block" : "none",
    //   transition: 'display 2s linear 1s' 
		// };
		
		// var hidden = {
    //   display: this.state.shown ? "none" : "block",
    //   ransition: 'display 2s linear 1s'
		// }
    return ( 
      
      <div className="content">

      {/* <img className="screen" src="http://marzenakrupa.pl/picker/page.png" /> */}

      <div className="App-header">
        <img src="https://www.frisco.pl/statics/img/icons/logo-white2.svg" height="40" alt="" />
        <button className="btn btn-default open-picker" onClick={this.toggle1.bind(this)}>Open picker 1</button>


        <div style={ shown1 } className="slotpicker">
          <or-slotpicker ref={elem => this.nv = elem} configuration={JSON.stringify(this.state.configuration)}></or-slotpicker>
        </div>

        
      </div>

       <Form clickHandler={this.handleClick} customerRef=""  />

       <div className="Box">
          <h5>JWT (response from server)</h5>
          <div className="json">{this.state.configuration.jwt}</div>
       </div>

       <div>
          <form onSubmit={this.customJWT}>
                <div>
                    <label>
                    CUSTOM JWT: 
                    <input className="form-control customjwt" type="text" ref={(input) => this.input = input} />
                    </label>
                </div>
                <input class="btn btn-default" type="submit" value="Submit" />
            </form>

       </div>

       <div>
        <form onSubmit={this.handleChangeConfiguration}>
          <span>CONFIG</span>
          <div>
            <textarea className="form-control" value={JSON.stringify(this.state.configuration)} onChange={this.handleChangeConf} cols={80} rows={20} />
            </div>
          {/* <input className="btn btn-default" type="submit" value="Submit" /> */}
        </form>
       </div>

        <div className="Box">
          <h5>POTWIERDZNIE REZERWACJI SLOTU (response from server)</h5>
          <div className="json">{this.state.detail}</div>
       </div>

       <div className="Box">
          <h5>Wygeneruj zamówienie</h5>
          <button className="btn btn-default" onClick={this.getDelivery}>GENERUJ</button>
       </div>

       <div className="Box">
          <h5>WYGENEROWANE LOSOWE ZAMWIENIE</h5>
          <div className="json">{this.state.deliveryConfirmation}</div>
       </div>
       
       <div className="Box">
          <h5>Wygeneruj zamówienie</h5>
          <button className="btn btn-default" onClick={this.confirmOrder}>Złóz zamówienie</button>
       </div>
      
      </div>

    );
  }

    
}

export default App;