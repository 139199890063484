export const addresses = [
["124e9587-305f-4697-8c41-d14e783eb23b", "9",  "Warszawa", "01-001", "Poland", "Ludwika Paszkiewicza", "52.16285140000001", "21.0193006"],
["125a7eae-e39f-460a-b26b-6deabb991452", "35",  "Warszawa", "01-905", "Poland", "Bajana 1", "52.2744999", "20.9341799"],
["1261a7c9-4951-455a-9fbf-f417fa95953d", "207",  "Piaseczno", "05-500", "Poland", "Puławska 45", "52.0905938", "21.0173026"],
["12736523-4377-48c9-8ec9-5f8ab9638b01", "1",  "Warszawa", "01-001", "Poland", "Wał Miedzeszyński 3", "52.1525082", "21.1876677"],
["12769c45-1596-4343-aa79-73d298885e84", "26",  "Warszawa", "04-549", "Poland", "Korkowa 61", "52.2412713", "21.1586104"],
["127c8084-2456-4056-ba32-806dac5ae778", "127",  "Warszawa", "01-001", "Poland", "Obrońców Tobruku 2", "52.2550594", "20.9315999"],
["127db69d-9ee1-4d8a-9542-11ae006713bc", "27",  "Warszawa", "00-401", "Poland", "Świętego Bonifacego 9", "52.1862208", "21.0634608"],
["128aa810-813b-43fa-b5e9-b5e04e234eea", "48",  "Warszawa", "02-001", "Poland", "Rakowska 1", "52.1962837", "20.9497636"],
["129d8420-865f-4da7-9e29-07e5b2c406d1", "173",  "Warszawa", "01-001", "Poland", "Aleksandra Gieysztora 20", "52.16037739999999", "21.0827154"],
["12c49d28-1ee2-41be-b626-932b40a2d16a", "140",  "Warszawa", "00-112", "Poland", "Bagno 1", "52.2360398", "21.0054292"],
["12cab027-4819-4e36-9428-065d6060a44b", "1",  "Warszawa", "01-001", "Poland", "Trakt Lubelski 275", "52.213302", "21.130294"],
["12d538f1-325c-4d72-8d4e-a70dba63e04d", "5",  "Warszawa", "00-401", "Poland", "Konstancińska 15", "52.186107", "21.0606319"],
["124e9587-305f-4697-8c41-d14e783eb23b", "9",  "Warszawa", "01-001", "Poland", "Ludwika Paszkiewicza", "52.16285140000001", "21.0193006"],
["125a7eae-e39f-460a-b26b-6deabb991452", "35",  "Warszawa", "01-905", "Poland", "Bajana 1", "52.2744999", "20.9341799"],
["1261a7c9-4951-455a-9fbf-f417fa95953d", "207",  "Piaseczno", "05-500", "Poland", "Puławska 45", "52.0905938", "21.0173026"],
["12736523-4377-48c9-8ec9-5f8ab9638b01", "1",  "Warszawa", "01-001", "Poland", "Wał Miedzeszyński 3", "52.1525082", "21.1876677"],
["12769c45-1596-4343-aa79-73d298885e84", "26",  "Warszawa", "04-549", "Poland", "Korkowa 61", "52.2412713", "21.1586104"],
["127c8084-2456-4056-ba32-806dac5ae778", "127",  "Warszawa", "01-001", "Poland", "Obrońców Tobruku 2", "52.2550594", "20.9315999"],
["127db69d-9ee1-4d8a-9542-11ae006713bc", "27",  "Warszawa", "00-401", "Poland", "Świętego Bonifacego 9", "52.1862208", "21.0634608"],
["128aa810-813b-43fa-b5e9-b5e04e234eea", "48",  "Warszawa", "02-001", "Poland", "Rakowska 1", "52.1962837", "20.9497636"],
["129d8420-865f-4da7-9e29-07e5b2c406d1", "173",  "Warszawa", "01-001", "Poland", "Aleksandra Gieysztora 20", "52.16037739999999", "21.0827154"],
["12c49d28-1ee2-41be-b626-932b40a2d16a", "140",  "Warszawa", "00-112", "Poland", "Bagno 1", "52.2360398", "21.0054292"],
["12cab027-4819-4e36-9428-065d6060a44b", "1",  "Warszawa", "01-001", "Poland", "Trakt Lubelski 275", "52.213302", "21.130294"],
["12d538f1-325c-4d72-8d4e-a70dba63e04d", "5",  "Warszawa", "00-401", "Poland", "Konstancińska 15", "52.186107", "21.0606319"],
["12e78c83-5733-4cd9-80e8-8324dbf24e8b", "1",  "Warszawa", "02-593", "Poland", "Stanisława Żaryna 2", "52.2043675", "20.9976645"]
];